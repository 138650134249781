import React, { lazy, Suspense } from "react";

import "./App.css";
import ErrorBoundary from "./ErrorBoundary";
import Loading from "./Loading";

const InvalidUsage = lazy(() => import("./InvalidUsage").then((module) => ({ default: module.default })));
const RedirectElement = lazy(() => import("./RedirectElement/RedirectElement").then((module) => ({ default: module.default })));

const App = () => {
  const invalidUsage = window.self === window.top;

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary>
        {invalidUsage && <InvalidUsage />}
        {!invalidUsage && <RedirectElement />}
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
