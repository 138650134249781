import React, { PureComponent } from "react";

import PropTypes from "prop-types";

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      error,
      hasError: true,
    };
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      hasError,
      error,
    } = this.state;

    if (hasError) {
      return (
        <div className="errorBoundary">
          <h3>Oops! Better fix this:</h3>
          <span>{error && error.stack}</span>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default ErrorBoundary;
