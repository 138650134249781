import React from "react";

const Loading = () => (
  <div className="loadingWrapper">
    <div className="loading">
      <div className="bubble" />
    </div>
  </div>
);

export default Loading;
